import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import {environment} from "../../../environments/environment";

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);

    let newReq = req.clone();

    // Aunque esté caducado enviamos el token para darnos cuenta de que ya no sirve, a la llamada de refresh-token no podemos añadirlo pq da error si está caducado
    if ( authService.accessToken && req.url !== `${environment.apiUrl}/users/refresh-token` )
    {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) =>
        {

            if(error.url === `${environment.apiUrl}/users/refresh-token`) {
                // Sign out
                authService.signOut();
                return throwError(error);
            }

            // Catch "401 Unauthorized" responses
            if ( error instanceof HttpErrorResponse && error.status === 401 )
            {
                if (!authService.isRefreshing()) {
                    return authService.callRefreshToken().pipe(
                        switchMap(() => {
                            const newReqWithToken = req.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${authService.accessToken}`
                                }
                            });
                            return next(newReqWithToken);
                        })
                    );
                } else {
                    // mientras se está refrescando el token las otras llamadas se tienen que esperar
                    return authService.getRefreshTokenSubject().pipe(
                        filter(token => token !== null),
                        take(1),
                        switchMap(() => {
                            // console.log('new bearer' + authService.accessToken.slice(-10) )
                            const newReqWithToken = req.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${authService.accessToken}`
                                }
                            });
                            return next(newReqWithToken);
                        })
                    );
                }


            }
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();
            }

            return throwError(error);
        }),
    );
};
