/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard'
    }
];

export const createDefaultNavigation = (userData: any): FuseNavigationItem[] => [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard'
    }
];

export const createCompactNavigation = (userData: any): FuseNavigationItem[] => [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard'
    },
    {
        id   : 'users',
        title: 'Usuarios',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/users',
        hidden: () => {
            return !userData || !userData.permissions.includes('LIST_USERS');
        }
    },
    {
        id   : 'accounts',
        title: 'Cuentas',
        type : 'basic',
        icon : 'heroicons_outline:squares-2x2',
        link : '/accounts',
        hidden: () => {
            return !userData || !userData.permissions.includes('READ_ACCOUNTS');
        }
    },
    {
        id   : 'projects',
        title: 'Proyectos',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-check',
        link : '/projects',
        hidden: () => {
            return !userData || !userData.permissions.includes('READ_PROJECTS');
        }
    },
    {
        id   : 'domains',
        title: 'Dominios',
        type : 'basic',
        icon : 'mat_outline:dns',
        link : '/domains',
        hidden: () => {
            return !userData || !userData.permissions.includes('READ_DOMAINS');
        }
    },
    {
        id   : 'sensia',
        title: 'Sensia',
        type : 'basic',
        icon : 'mat_outline:apps',
        link : '/sensia',
        hidden: () => {
            return !userData || !userData.permissions.includes('MANAGE_SCHEMAS');
        }
    },
    {
        id   : 'sensia-audit',
        title: 'Sensia audits',
        type : 'basic',
        icon : 'heroicons_outline:shield-check',
        link : '/sensia-audit',
        hidden: () => {
            return !userData || !userData.permissions.includes('DO_AUDITS');
        }
    }
];

export const createFuturisticNavigation = (userData: any): FuseNavigationItem[] => [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];

export const createHorizontalNavigation = (userData: any): FuseNavigationItem[] => [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];